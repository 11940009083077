import React from "react";
import { PropTypes } from "prop-types";
import "./header-logo.m.css";

const HeaderLogo = ({ width = "200", height = "94", className = "desktop" }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 200 100" styleName={className}>
      <g fill="none" fillRule="evenodd">
        <path
          fill="#0E396E"
          d="M34.67 6h-.01c-.445.003-14.717.23-18.586 14.679L.478 78.936s-3.93 14.678 10.737 14.678h153.992s14.666 0 18.596-14.678l15.596-58.257S203.329 6 188.662 6H34.67"
        />
        <path
          fill="#FFFFFE"
          d="M79.876 20.495l2.702-10.092c-1.619-.458-3.22-.642-7.07-.642-5.316 0-11.34 3.67-13.354 11.193l-1.94 7.248h-3.392l-2.8 10.459h3.392L47.319 76.367h11.458l10.095-37.706h4.858l2.8-10.46h-4.858l1.35-5.045c.566-2.11 1.777-3.211 3.61-3.211 1.283 0 2.334.183 3.244.55M83.457 67.377c-.697.55-1.388.733-2.488.733-1.925 0-2.033-1.651-.657-6.789 1.498-5.596 2.624-7.065 4.274-7.065 1.191 0 1.692.185 2.211.643l-3.34 12.478zm9.784 8.99l9.726-36.33c2.53-9.45-1.053-12.844-9.67-12.844-5.04 0-11.111 2.476-15.564 5.412l1.309 8.807c4.054-2.477 6.964-3.761 9.44-3.761 2.291 0 2.638 1.101 1.926 3.761l-1.4 5.23c-.995-.734-2.498-1.285-4.79-1.285-7.608 0-12.424 5.322-15.322 16.148-2.922 10.917-1.408 15.871 5.1 15.871 2.934 0 5.153-1.1 7.668-3.303l-.614 2.294H93.24zM116.73 66c-.905.642-1.76 1.1-2.586 1.1-1.833 0-2.314-1.283 1.296-14.77 3.66-13.67 4.78-14.77 6.521-14.77 1.009 0 1.686.55 2.089 1.1L116.73 66zm5.305 23.67l16.456-61.468H126.85l-.565 2.11c-1.25-1.835-3.29-3.12-5.765-3.12-6.691 0-12.17 7.799-16.886 25.414-4.274 15.963-3.974 24.77 3.36 24.77 2.933 0 5.617-1.468 7.576-3.303l-5.13 19.536 12.595-3.939zM159.55 63.89c1.795-6.697 1.421-11.469-5.037-16.789-4.09-3.212-5.164-4.68-4.23-8.166.441-1.651 1.42-2.568 2.978-2.568 1.467 0 1.826 1.743 1.918 5.504l10.558-3.486c-.26-7.247-2.32-11.192-9.836-11.192-8.432 0-14.44 5.32-16.577 13.302-1.67 6.239-.881 10.826 5.467 15.872 3.93 3.12 4.564 4.863 3.533 8.715-.564 2.11-1.66 3.12-3.401 3.12-1.467 0-2.008-1.743-1.874-6.697l-10.741 3.486c-.18 7.523 1.725 12.385 9.7 12.385 8.158 0 15.16-4.587 17.543-13.486"
        />
        <path
          fill="#F3B229"
          d="M30.662 67.377c-.698.55-1.388.733-2.488.733-1.925 0-2.033-1.651-.657-6.789 1.498-5.596 2.624-7.065 4.274-7.065 1.192 0 1.692.185 2.211.643l-3.34 12.478zm9.784 8.99l9.726-36.33c2.53-9.45-1.053-12.844-9.669-12.844-5.042 0-11.113 2.476-15.565 5.412l1.308 8.807c4.055-2.477 6.965-3.761 9.44-3.761 2.292 0 2.639 1.101 1.927 3.761l-1.4 5.23c-.996-.734-2.498-1.285-4.79-1.285-7.607 0-12.424 5.322-15.322 16.148-2.923 10.917-1.408 15.871 5.1 15.871 2.934 0 5.153-1.1 7.668-3.303l-.614 2.294h12.191zM181.78 42.146l8.03-30h-11.916l-8.031 30-2.736 17.065h8.25l6.402-17.065M175.106 70.496c1.106-4.129-.883-6.973-5.008-6.973s-7.728 2.844-8.834 6.973c-1.08 4.036 1 6.88 5.125 6.88s7.636-2.844 8.717-6.88"
        />
      </g>
    </svg>
  );
};

export default HeaderLogo;

HeaderLogo.propTypes = {
  width: PropTypes.string,
  height: PropTypes.string,
  className: PropTypes.string
};
