import React from "react";

import { Link } from "@quintype/components";
import { object, string, func, number, array } from "prop-types";
import cx from "classnames";
import Close from "../icons/close";

import "./header-menu-column.m.css";

const HeaderMenuColumn = props => {
  const getIcon = item => {
    const { activeDropDownId } = props;
    if (activeDropDownId[item.id]) {
      return <Close />;
    } else {
      return <span styleName="chevron-down" />;
    }
  };

  const { headerMenuColumn, currentPath } = props;
  const isMobileView = global.innerWidth <= 768;

  const style = cx("list", {
    active: currentPath === headerMenuColumn.completeUrl || currentPath === `/${headerMenuColumn.completeUrl}`
  });

  const generateChildItemsDom = (childItems, isMobileView) => {
    return childItems.map((childItem, index) => {
      const grandChildItems = childItem.children || [];

      if (grandChildItems.length) {
        return getMenuItemsView(childItem, true);
      }

      let newProps = {};

      if (childItem.isExternalLink) {
        newProps = { target: "_blank" };
      }

      return (
        <li key={index}>
          <Link
            href={childItem.completeUrl}
            styleName="child-item"
            externalLink={childItem.isExternalLink && childItem.completeUrl}
            {...newProps}
          >
            <div styleName="item-title" onClick={isMobileView ? props.toggleMenuBar : null}>
              {childItem.title}
            </div>
          </Link>
        </li>
      );
    });
  };

  const getMenuItemsView = (item, isGrandChild = false) => {
    const childItems = item.children || [];
    const childItemsDom = childItems.length ? generateChildItemsDom(childItems, isMobileView) : null;
    const showDropdown = props.activeDropDownId[item.id];
    return (
      <ul styleName={style}>
        {item.title && (
          <li onClick={() => (isMobileView ? props.toggleDropDown(item.id) : null)}>
            <Link
              styleName="parent-menu-item"
              href={item.completeUrl || "#"}
              externalLink={item.isExternalLink && item.completeUrl}
            >
              {item.title === "Live" ? (
                <a href={item.completeUrl || "#"} target="_blank" rel="noopener noreferrer">
                  <div styleName="live-btn" onClick={isMobileView ? props.toggleMenuBar : null}>
                    {item.title}
                    <div styleName="live-icon" />
                  </div>
                </a>
              ) : (
                <div styleName="item-title" onClick={isMobileView ? props.toggleMenuBar : null}>
                  {item.title}
                </div>
              )}
            </Link>
            {childItemsDom ? <span styleName="close-icon">{getIcon(item)}</span> : <span styleName="empty-icon" />}
          </li>
        )}
        {showDropdown && <ul styleName="child-menu-list">{childItemsDom}</ul>}
        {childItemsDom && (
          <ul styleName={`desktop child-menu-list ${isGrandChild ? "grandChild" : ""}`}>{childItemsDom}</ul>
        )}
      </ul>
    );
  };
  getMenuItemsView.propTypes = {
    activeDropDownId: array,
    toggleDropDown: func,
    toggleMenuBar: func
  };
  return getMenuItemsView(headerMenuColumn);
};

HeaderMenuColumn.propTypes = {
  headerMenuColumn: object,
  currentPath: string,
  toggleDropDown: func,
  toggleMenuBar: func,
  activeDropDownId: array,
  index: number,
  label: string
};

export default HeaderMenuColumn;
