/* eslint-disable max-len */
import { get, isNull } from "lodash";

export const getSlug = (collection, sections = []) => {
  let slug = null;

  if (collection && collection.slug) {
    const metadata = Object.keys(get(collection, ["metadata"], {}));
    const isSection = metadata.indexOf("section") !== -1;
    if (isSection) {
      const sectionId = collection.metadata.section[0].id;
      const section = sections.find(o => o.id === sectionId);

      if (section && !isNull(section["parent-id"])) {
        const parentSection = sections.find(o => o.id === section["parent-id"]);

        if (parentSection) {
          slug = `/${parentSection.slug}/${section.slug}`;
        }
      } else if (section && isNull(section["parent-id"])) {
        slug = `/${section.slug}`;
      }
    }
  }

  return slug;
};

export const isIplStory = story => {
  const tags = story && story.tags;
  return tags.some(item => {
    if (item.slug === "ipl-2024" || item.slug === "wpl-2024" || item.slug === "jiocinema" || item.slug === "sports18") {
      return true;
    } else {
      return false;
    }
  });
};

export const shouldRemoveBanner = slug => {
  const campaignStory = [
    "news/marketing-initiatives/star-sports-revolutionizes-tv-for-advertisers-this-ipl-with-a-host-of-ground-breaking-brand-solutions",
    "news/marketing-initiatives/2023-the-year-when-cricket-on-television-created-and-recreated-history",
    "news/marketing-initiatives/ipl-on-tv-fueling-3x-growth-in-brand-and-business-impact-for-advertisers-across-categories"
  ];

  if (campaignStory.includes(slug)) {
    return true;
  }
  return false;
};
