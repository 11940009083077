/* eslint-disable max-len */
/* eslint-disable react/prop-types */
import React from "react";

const Twitter = ({ width = "24", height = "24" }) => (
  <svg
    id="Layer_1"
    data-name="Layer 1"
    xmlns="http://www.w3.org/2000/svg"
    version="1.1"
    viewBox="0 0 50 50"
    width={width}
    height={height}
  >
    <defs>
      <style>
        {`
          .cls-tw-1 {
            fill: none;
            stroke: #f8f7f7;
            stroke-miterlimit: 10;
            stroke-width: 0.75px;
          }

          .cls-tw-2 {
            fill: #fff;
            stroke-width: 0px;
          }
        `}
      </style>
    </defs>
    <path
      className="cls-tw-1"
      d="M47.5,25c0,12.43-10.07,22.5-22.5,22.5h0c-12.43,0-22.5-10.07-22.5-22.5h0C2.5,12.57,12.57,2.5,25,2.5h0c12.43,0,22.5,10.07,22.5,22.5h0Z"
    />
    <path
      className="cls-tw-2"
      d="M28.25,22.93l9.1-9.74h-3.46l-7.14,7.64-5.47-7.64h-9.45l9.57,13.37-9.57,10.24h3.46l7.61-8.14,5.83,8.14h9.45l-9.93-13.87ZM16.7,15.7h3.3l13.31,18.6h-3.3l-13.31-18.6Z"
    />
  </svg>
);

export default Twitter;
