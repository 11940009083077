/* eslint-disable react/prop-types */
import React from "react";
import { connect } from "react-redux";
import get from "lodash/get";

import HeaderLogo from "../../atoms/icons/header-logo";
import "./mobile-header.m.css";
import HamburgerIcon from "../../atoms/icons/hamburger";
import Navbar from "../../molecules/navbar";
import { Link } from "@quintype/components";
import AdWrapper from "../../rows/ads";
import { getStoryBySlug } from "../../../utils/api";
import { isIplStory } from "../../../utils";
class MobileHeader extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isMenubarActive: false,
      isIPL: false,
      iplStory: false
    };

    this.toggleMenuBar = this.toggleMenuBar.bind(this);
  }
  async checkCustomAd() {
    // Check if pathname is '/topic/ipl-2024' and update isIPL state
    if (window.location.pathname === "/topic/ipl-2024" || window.location.pathname === "/topic/wpl-2024") {
      this.setState({
        isIPL: true
      });
    }
    const currentUrl = window.location.pathname;
    if (this.props.pageType === "story-page") {
      const slug = currentUrl.slice(1);
      const storyData = await getStoryBySlug(slug);
      this.setState({
        ...this.state,
        iplStory: isIplStory(storyData.story)
      });
    }
  }

  componentDidMount() {
    this.checkCustomAd();
  }

  componentDidUpdate(prevProps) {
    if (this.props.pageType !== prevProps.pageType) {
      this.checkCustomAd();
    }
  }
  toggleMenuBar = () => {
    this.setState({
      isMenubarActive: !this.state.isMenubarActive
    });
  };

  renderAds() {
    if (this.state.iplStory || this.state.isIPL) {
      return <AdWrapper id="home-IPLtopBanner-mobile" name="IPLtopBanner" />;
    }

    return <AdWrapper id="home-topBanner-mobile" name="topBanner" />;
  }

  render() {
    return (
      <div styleName="mobile-wrapper">
        <div styleName="top-banner-mobile">{this.renderAds()}</div>
        <div styleName="wrapper">
          <Link href="/" styleName="brand">
            <HeaderLogo width={"144"} height={"72"} className={"mobile"} />
          </Link>
          <div styleName="search-wrapper" onClick={this.toggleMenuBar}>
            <HamburgerIcon />
          </div>
        </div>
        {this.state.isMenubarActive && <Navbar toggleMenuBar={this.toggleMenuBar} />}
      </div>
    );
  }
}
const mapStateToProps = state => {
  return {
    pageType: get(state, ["qt", "pageType"], "")
  };
};

export default connect(
  mapStateToProps,
  null
)(MobileHeader);
