/* eslint-disable max-len */
/* eslint-disable no-undef */
import React, { useEffect, useState } from "react";
import HeaderLogo from "../../atoms/icons/header-logo";

import "./subscription-popup.m.css";

const SubscriptionPopup = () => {
  const [showPopup, setShowPopup] = useState(false);
  const [email, setEmail] = useState("");
  // const [error, showError] = useState(false);
  useEffect(() => {
    const hasPopupShown = window.sessionStorage.getItem("popUpShown");

    if (!hasPopupShown) {
      const timeout = setTimeout(() => {
        setShowPopup(true);
        window.sessionStorage.setItem("popUpShown", true);
      }, 35000);
      return () => clearTimeout(timeout);
    }
  }, []);

  const handleCloseClick = event => {
    event.preventDefault();
    setShowPopup(false);
  };

  const handleEmailChange = event => {
    setEmail(event.target.value);
  };

  // const validateEmail = () => {
  //   const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  //   if (!emailRegex.test(email)) {
  //     showError(true);
  //     return false;
  //   }
  //   return true;
  // };

  // const handleSubmit = e => {
  //   e.preventDefault();

  //   document.cookie =
  //     "newsletterSubmitted=true; expires=" + new Date(new Date().getTime() + 7 * 24 * 60 * 60 * 1000).toUTCString(); // expires in 7 days

  //   e.target.submit();

  //   return false;
  // };

  return (
    <React.Fragment>
      {/* {showPopup && (
        <div styleName="subscription-popup-wrapper">
          <div styleName="popup-container">
            <iframe
              title="Afaqs Subscription"
              src="https://www.afaqs.com/labs/signup/afaqs_subscription.html"
              width="100%"
              height="363px"
              frameBorder="0"
              scrolling="no"
            />
          </div>
        </div>
      )} */}

      {showPopup && (
        <div styleName="subscription-popup-wrapper">
          <a href="https://www.afaqs.com/labs/signup/afaqs_subscribe.html">
            <div styleName="popup-container">
              <div styleName="close-btn" onClick={e => handleCloseClick(e)}>
                <div styleName="incline" />
                <div styleName="decline" />
              </div>

              <div styleName="logo-wrapper">
                <HeaderLogo width={"50"} height={"25"} className="mobile" />
                <HeaderLogo width={"115"} height={"84"} className={"desktop"} />
              </div>

              <div styleName="text-wrapper">
                <p styleName="text">Sign up to receive your daily dose of</p>
                <p styleName="text">insights on advertising, media, and marketing.</p>
              </div>
              <form
                name="regform"
                action="https://agency.afaqs.com/afaqs_registration/registrations_form.php"
                method="post"
              >
                <div>
                  <input
                    type="email"
                    placeholder="Email"
                    styleName="input-box"
                    value={email}
                    onChange={handleEmailChange}
                  />
                </div>
                {/* {error && <p styleName="error">Please enter a correct email</p>} */}
                <div styleName="sign-up-wrapper">
                  <button styleName="sign-up">Sign Up</button>
                </div>
              </form>
            </div>
          </a>
        </div>
      )}
    </React.Fragment>
  );
};

export default SubscriptionPopup;
