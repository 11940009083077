/* eslint-disable react/prop-types */
import React from "react";

const Close = ({ color = "#FFF", width = "14", height = "14" }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 12 12">
      <path
        fill={color}
        fillRule="nonzero"
        d="M9.33 1.08a.547.547 0 0 1 .397-.175c.15 0 .282.058.398.174l.796.796a.547.547 0 0 1 .174.398c0 .149-.058.281-.174.397L7.34 6.25l3.58 3.58a.547.547 0 0 1 .174.397c0 .15-.058.282-.174.398l-.796.796a.547.547 0 0 1-.398.174.547.547 0 0 1-.397-.174L5.75 7.84l-3.58 3.58a.547.547 0 0 1-.397.174.547.547 0 0 1-.398-.174l-.796-.796a.547.547 0 0 1-.174-.398c0-.149.058-.281.174-.397l3.58-3.58-3.58-3.58a.548.548 0 0 1-.174-.397c0-.15.058-.282.174-.398l.796-.796a.547.547 0 0 1 .398-.174c.149 0 .281.058.397.174l3.58 3.58 3.58-3.58z"
      />
    </svg>
  );
};

export default Close;
