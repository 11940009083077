import React from "react";
import "./hamburger.m.css";

const HamburgerIcon = () => {
  return (
    <div>
      <div styleName="line" />
      <div styleName="line" />
      <div styleName="line" />
    </div>
  );
};

export default HamburgerIcon;
