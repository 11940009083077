import React from "react";
import { connect } from "react-redux";
import { shape, string } from "prop-types";
import get from "lodash/get";

import Facebook from "../../atoms/icons/footer/facebook";
import Instagram from "../../atoms/icons/footer/instagram";
import Linkedin from "../../atoms/icons/footer/Linkedin";
import Twitter from "../../atoms/icons/footer/twitter";
import Whatsapp from "../../atoms/icons/footer/Whatsapp";
import Youtube from "../../atoms/icons/footer/Youtube";

import "./social-links.m.css";

export const SocialLinksBase = ({ socialLinks, linkedin }) => {
  return (
    <ul styleName="container">
      <li styleName="social-link">
        <a href="https://www.facebook.com/afaqs?mibextid=LQQJ4d" target="_blank" rel="noopener noreferrer">
          <Facebook />
        </a>
      </li>
      <li styleName="social-link">
        <a href="https://www.instagram.com/afaqsdotcom?igsh=eWdydGdmZm1mbmY5" target="_blank" rel="noopener noreferrer">
          <Instagram />
        </a>
      </li>
      <li styleName="social-link">
        <a
          href="https://www.linkedin.com/company/banyan-netfaqs-pvt-ltd--afaqs--/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <Linkedin />
        </a>
      </li>
      <li styleName="social-link">
        <a href="https://x.com/afaqs" target="_blank" rel="noopener noreferrer">
          <Twitter />
        </a>
      </li>
      <li styleName="social-link">
        <a href="https://whatsapp.com/channel/0029Va79N9vLdQekWdUZXd2k" target="_blank" rel="noopener noreferrer">
          <Whatsapp />
        </a>
      </li>
      <li styleName="social-link">
        <a href="https://www.youtube.com/@afaqsdotcom" target="_blank" rel="noopener noreferrer">
          <Youtube />
        </a>
      </li>
    </ul>
  );
};

const mapStateToProps = state => {
  return {
    socialLinks: get(state, ["qt", "config", "social-links"]) || {},
    linkedin: get(state, ["qt", "config", "publisher-attributes", "linkedin"]) || ""
  };
};

SocialLinksBase.propTypes = {
  socialLinks: shape({
    facebook: string,
    twitter: string
  }),
  linkedin: string
};

export const SocialLinks = connect(mapStateToProps)(SocialLinksBase);

export default SocialLinks;
