/* eslint-disable max-len */
/* eslint-disable react/prop-types */
import React from "react";

const Facebook = ({ width = "24", height = "24" }) => (
  <svg
    id="Layer_1"
    data-name="Layer 1"
    xmlns="http://www.w3.org/2000/svg"
    version="1.1"
    width={width}
    height={height}
    viewBox="0 0 50 50"
  >
    <defs>
      <style>
        {`
          .cls-fb-1 {
            fill: none;
            stroke: #f8f7f7;
            stroke-miterlimit: 10;
            stroke-width: 0.5px;
          }

          .cls-fb-2 {
            fill: #fff;
            stroke-width: 0px;
          }
        `}
      </style>
    </defs>
    <path
      className="cls-fb-1"
      d="M47.5,25c0,12.43-10.07,22.5-22.5,22.5h0c-12.43,0-22.5-10.07-22.5-22.5h0C2.5,12.57,12.57,2.5,25,2.5h0c12.43,0,22.5,10.07,22.5,22.5h0Z"
    />
    <path
      className="cls-fb-2"
      d="M31.67,23.25l-.37,3.01c-.06.5-.49.88-.99.88h-4.88v12.59c-.51.05-1.04.07-1.56.07-1.18,0-2.33-.12-3.44-.34v-12.31h-3.75c-.34,0-.63-.28-.63-.63v-3.77c0-.34.28-.63.63-.63h3.75v-5.65c0-3.47,2.8-6.28,6.25-6.28h4.37c.34,0,.63.28.63.63v3.77c0,.34-.28.63-.63.63h-3.13c-1.38,0-2.5,1.12-2.5,2.51v4.39h5.25c.6,0,1.07.53.99,1.13Z"
    />
  </svg>
);

export default Facebook;
