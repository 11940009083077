/* eslint-disable global-require */
import { startApp } from "@quintype/framework/client/start";
import { renderApplication, preRenderApplication } from "./render";
import "../../app/assets/stylesheets/app.scss";
import wretch from "wretch";

function enableHotReload(store) {
  if (process.env.NODE_ENV === "development" && module.hot) {
    module.hot.accept("./render", () => renderApplication(store));
  }
}

const intialState = {
  showHptoAd: false,
  siteCapture: false,
  carouselAds: "",
  adPrefix: "home"
};

function headerReducer(state = intialState, action) {
  switch (action.type) {
    case "SHOW_HPTO_AD":
      return {
        ...state,
        showHptoAd: action.payload
      };
    case "SHOW_SITE_CAPTURE_AD":
      return {
        ...state,
        siteCapture: action.payload
      };
    case "CAROUSEL_AD":
      return {
        ...state,
        carouselAds: action.payload
      };
    case "AD_PREFIX":
      return {
        ...state,
        adPrefix: action.payload
      };

    default:
      return state;
  }
}

const CUSTOM_REDUCERS = {
  adsReducer: headerReducer
};

global.wretch = wretch;
global.width = window.innerWidth;

startApp(renderApplication, CUSTOM_REDUCERS, {
  enableServiceWorker: process.env.NODE_ENV === "production",
  appVersion: require("../isomorphic/app-version"),
  preRenderApplication
}).then(enableHotReload);
