import React, { Component } from "react";
import { func, any } from "prop-types";
import Close from "../../atoms/icons/close";
import "./styles.m.css";

class SimpleModal extends Component {
  constructor(props) {
    super(props);

    this.handleKeyUp = this.handleKeyUp.bind(this);
    this.handleOutsideClick = this.handleOutsideClick.bind(this);
  }

  componentDidMount() {
    const { closeAd } = this.props;
    setTimeout(() => {
      closeAd();
    }, 10000);
    window.addEventListener("keyup", this.handleKeyUp, false);
    document.addEventListener("click", this.handleOutsideClick, false);
    this.props.createCookie();
  }

  componentWillUnmount() {
    window.removeEventListener("keyup", this.handleKeyUp, false);
    document.removeEventListener("click", this.handleOutsideClick, false);
  }

  handleKeyUp(e) {
    const { closeAd } = this.props;
    const keys = {
      27: () => {
        e.preventDefault();
        closeAd();
        window.removeEventListener("keyup", this.handleKeyUp, false);
      }
    };

    if (keys[e.keyCode]) {
      keys[e.keyCode]();
    }
  }

  handleOutsideClick(e) {
    const { closeAd } = this.props;

    if (this.modal) {
      if (!this.modal.contains(e.target)) {
        closeAd();
        document.removeEventListener("click", this.handleOutsideClick, false);
      }
    }
  }

  render() {
    const { closeAd, children } = this.props;

    return (
      <div styleName="modalOverlay">
        <div styleName="model" ref={node => (this.modal = node)}>
          <div>{children}</div>
        </div>
        <div styleName="close-icon" onClick={() => closeAd()}>
          <Close />
        </div>
      </div>
    );
  }
}

SimpleModal.propTypes = {
  closeAd: func,
  createCookie: func,
  children: any
};

export default SimpleModal;
