import React from "react";
import DesktopLogoBar from "../../molecules/desktop-logo-bar";
import Navbar from "../../molecules/navbar";

import "./desktop-header.m.css";

const DesktopHeader = () => {
  return (
    <div styleName="desktop-wrapper">
      <DesktopLogoBar />
      <Navbar />
    </div>
  );
};

export default DesktopHeader;
