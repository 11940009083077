import React from "react";

import { Link } from "@quintype/components";
import { object, string, number } from "prop-types";

import "./secondary-header-menu-column.m.css";

const SecondaryHeaderMenuColumn = props => {
  const { headermenuColumn } = props;

  const generateChildItemsDom = childItems => {
    return childItems.map((childItem, index) => {
      const { completeUrl, isExternalLink, title, children: grandChildItems = [] } = childItem;

      if (grandChildItems.length) {
        return getMenuItemsView(childItem, true);
      }

      let newProps = {};

      if (isExternalLink) {
        newProps = { target: "_blank" };
      }

      return (
        <li key={index}>
          <Link href={completeUrl} styleName="child-item" externalLink={isExternalLink && completeUrl} {...newProps}>
            <div styleName="item-title">{title}</div>
          </Link>
        </li>
      );
    });
  };

  const getMenuItemsView = item => {
    const { children: childItems = [], title, isExternalLink, completeUrl } = item;
    const childItemsDom = childItems.length ? generateChildItemsDom(childItems) : null;

    return (
      <ul styleName="parent-title">
        {title && (
          <li>
            <Link styleName="parent-menu-item" href={item.url} externalLink={isExternalLink && completeUrl}>
              <div styleName="item-title">{title}</div>
            </Link>
            {childItemsDom ? <span styleName="chevron-down" /> : <span />}
          </li>
        )}
        {childItemsDom && <ul styleName="child-menu-list">{childItemsDom}</ul>}
      </ul>
    );
  };
  return getMenuItemsView(headermenuColumn);
};

SecondaryHeaderMenuColumn.propTypes = {
  headerMenuColumn: object,
  currentPath: string,
  index: number,
  label: string
};

export default SecondaryHeaderMenuColumn;
