/* eslint-disable max-len */
/* eslint-disable react/prop-types */
import React from "react";

const Instagram = ({ width = "24", height = "24" }) => (
  <svg
    id="Layer_1"
    data-name="Layer 1"
    xmlns="http://www.w3.org/2000/svg"
    version="1.1"
    viewBox="0 0 50 50"
    width={width}
    height={height}
  >
    <defs>
      <style>
        {`
          .cls-ig-1 {
            fill: #fff;
            stroke-width: 0px;
          }

          .cls-ig-2 {
            fill: none;
            stroke: #f4f4fa;
            stroke-miterlimit: 10;
            stroke-width: 0.75px;
          }
        `}
      </style>
    </defs>
    <path
      className="cls-ig-2"
      d="M47.5,25c0,12.43-10.07,22.5-22.5,22.5h0c-12.43,0-22.5-10.07-22.5-22.5h0C2.5,12.57,12.57,2.5,25,2.5h0c12.43,0,22.5,10.07,22.5,22.5h0Z"
    />
    <path
      className="cls-ig-1"
      d="M31.1,13.2h-12.21c-3.15,0-5.7,2.55-5.7,5.7v12.21c0,3.15,2.55,5.7,5.7,5.7h12.21c3.15,0,5.7-2.55,5.7-5.7v-12.21c0-3.15-2.55-5.7-5.7-5.7ZM34.77,30.7c0,2.25-1.82,4.07-4.07,4.07h-11.4c-2.25,0-4.07-1.82-4.07-4.07v-11.4c0-2.25,1.82-4.07,4.07-4.07h11.4c2.25,0,4.07,1.82,4.07,4.07v11.4Z"
    />
    <path
      className="cls-ig-1"
      d="M25.01,18.9c-3.37,0-6.1,2.73-6.1,6.1s2.73,6.1,6.1,6.1,6.1-2.73,6.1-6.1-2.73-6.1-6.1-6.1ZM25.01,29.07c-2.24,0-4.07-1.83-4.07-4.07s1.83-4.07,4.07-4.07,4.07,1.83,4.07,4.07-1.83,4.07-4.07,4.07Z"
    />
    <circle className="cls-ig-1" cx={31.52} cy={18.49} r={1.22} />
  </svg>
);

export default Instagram;
