import React from "react";
import "./social-links-with-label.m.css";
import SocialLinks from "../../molecules/social-links";
import PropTypes from "prop-types";

export const SocialLinksWithLabel = ({ text }) => {
  return (
    <div styleName="social-links-with-label" className="social-links-with-label">
      <p className="label-text" styleName="label-text">
        {text}
      </p>
      <SocialLinks />
    </div>
  );
};

SocialLinksWithLabel.propTypes = {
  text: PropTypes.string
};

export default SocialLinksWithLabel;
