import React from "react";
import { connect } from "react-redux";
import get from "lodash/get";
import HeaderMenuColumn from "../../atoms/header-menu-column/index";

import "./navbar.m.css";
import { array, string, func } from "prop-types";
import Search from "../../atoms/icons/search";
import { SearchBox } from "@quintype/components";

function SearchForm({ children }) {
  return [
    <label styleName="qt-search-label" htmlFor="searchForm" key="1">
      {children}
      <button type="submit" key="3" styleName="search-button-image">
        <Search />
      </button>
    </label>,
    <button type="submit" key="2" styleName="search-button">
      SEARCH
    </button>
  ];
}

class NavbarBase extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isSearchBoxActive: false,
      activeDropDownId: {},
      navClass: "navbar"
    };

    this.toggleSearchBox = this.toggleSearchBox.bind(this);
    this.renderSearchBox = this.renderSearchBox.bind(this);
  }

  componentDidMount() {
    window.addEventListener("scroll", this.listenToScroll);
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.listenToScroll);
  }

  getOffSet(element) {
    const elementRect = element && element.getBoundingClientRect();
    return elementRect && elementRect.top;
  }

  listenToScroll = () => {
    const scrollTop = window.scrollY;
    if (scrollTop >= 90) {
      this.setState({ navClass: "navbar sticky-navbar" });
    } else {
      this.setState({ navClass: "navbar" });
    }
  };

  toggleDropDown(id) {
    this.setState({
      activeDropDownId: {
        ...this.state.activeDropDownId,
        [id]: !this.state.activeDropDownId[id]
      }
    });
  }

  toggleNavBar = () => {
    // this.props.toggleMenuBar;
    this.setState({
      activeDropDownId: {}
    });
  };

  generateMenuColumns = (parentLinks, allLinks, currentPath, label = "") =>
    parentLinks.map((parentItemLink, index) => (
      <HeaderMenuColumn
        currentPath={currentPath}
        key={index}
        headerMenuColumn={parentItemLink}
        index={index}
        activeDropDownId={this.state.activeDropDownId}
        toggleDropDown={id => this.toggleDropDown(id)}
        toggleMenuBar={this.toggleNavBar}
        label={label}
      />
    ));

  toggleSearchBox = () => {
    this.setState({ isSearchBoxActive: !this.state.isSearchBoxActive });
  };

  renderSearchBox = device => {
    return (
      <SearchBox
        styleName={`qt-search__form ${device}`}
        template={SearchForm}
        inputId="searchForm"
        inputRef={input => (this.input = input)}
        inputClassName="qt-search__form-input"
        formRef={searchForm => (this.searchForm = searchForm)}
        onSubmitHandler={() => {
          this.setState({ isSearchBoxActive: false });
          this.props.toggleMenuBar();
        }}
        onEscape={() => this.setState({ isSearchBoxActive: false })}
        placeholder="Search"
      />
    );
  };

  render() {
    const getParentLinks = links => {
      return links.filter(item => {
        return item["parent-id"] === null;
      });
    };
    const parentMenuLinks = getParentLinks(this.props.headerLinks);
    const morePlaceholderLink = getParentLinks(this.props.moreLinks);
    return (
      <React.Fragment>
        {this.renderSearchBox("mobile")}
        <div id="nav" styleName={this.state.navClass}>
          <div styleName="wrapper">
            {this.generateMenuColumns(parentMenuLinks.slice(0, 6), this.props.headerLinks, this.props.currentPath)}
            {this.generateMenuColumns(morePlaceholderLink, this.props.moreLinks, this.props.currentPath)}
            {this.generateMenuColumns(parentMenuLinks.slice(6), this.props.headerLinks, this.props.currentPath)}
            <span styleName="search-lens" onClick={this.toggleSearchBox}>
              <Search />
            </span>
          </div>
        </div>
        {this.state.isSearchBoxActive && this.renderSearchBox("desktop")}
      </React.Fragment>
    );
  }
}

const mapStateToProps = state => {
  return {
    headerLinks: get(state, ["qt", "data", "navigationMenu", "headerLinks"], []) || {},
    moreLinks: get(state, ["qt", "data", "navigationMenu", "moreLinks"], []) || {},
    currentPath: get(state, ["qt", "currentPath"], "") || ""
  };
};

const Navbar = connect(mapStateToProps)(NavbarBase);

NavbarBase.propTypes = {
  secondNavbarLinks: array,
  headerLinks: array,
  moreLinks: array,
  currentPath: string,
  toggleMenuBar: func
};

export default Navbar;
