import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import get from "lodash/get";
import throttle from "lodash/throttle";
import { array, string } from "prop-types";
import SecondaryHeaderMenuColumn from "../../atoms/secondary-header-menu-column";
import "./navbar.m.css";

const SecondaryNavbarBase = props => {
  const [secondNavClass, setSecondNavClass] = useState("secondary-navbar");

  useEffect(() => {
    const listenToScroll = () => {
      const scrollTop = window.scrollY;
      if (scrollTop >= 90) {
        setSecondNavClass("secondary-navbar sticky");
      } else {
        setSecondNavClass("secondary-navbar");
      }
    };
    window.addEventListener("scroll", throttle(listenToScroll, 100));
    return () => {
      window.removeEventListener("scroll", listenToScroll);
    };
  });

  const { secondNavbarLinks, currentPath } = props;

  const generateSecondaryMenuColumns = (parentLinks, allLinks, currentPath) =>
    parentLinks.map((parentItemLink, index) => {
      return (
        <div styleName="nav-item" key={index}>
          <SecondaryHeaderMenuColumn currentPath={currentPath} headermenuColumn={parentItemLink} index={index} />
          {index === parentLinks.length - 1 ? "" : <span styleName="bullet-point" />}
        </div>
      );
    });
  const getParentLinks = links => {
    return links.filter(item => {
      return item["parent-id"] === null;
    });
  };
  const secondaryNavLinks = getParentLinks(props.secondNavbarLinks);

  return (
    <React.Fragment>
      <div styleName={secondNavClass}>
        <div styleName="nav-item-wrapper">
          {generateSecondaryMenuColumns(secondaryNavLinks, secondNavbarLinks, currentPath)}
        </div>
        <a href="https://www.afaqs.com/labs/signup/afaqs_subscribe.html" styleName="news-letter">
          <p>Sign up for afaqs! Newsletters</p>
          <img src="https://www.afaqs.com/labs/demo/envelope.svg" alt="envelope" />
        </a>
      </div>
      <a href="https://www.afaqs.com/labs/signup/afaqs_subscribe.html" styleName="news-letter-strip">
        <p>Sign up for afaqs! Newsletters</p>
        <img src="https://www.afaqs.com/labs/demo/envelope.svg" alt="envelope" />

        {/* <span>
          Sign up for <a href="https://www.afaqs.com/labs/signup/afaqs_subscribe.html">afaqs! Newsletters</a> and get
          the latest insights straight to your inbox
        </span> */}
      </a>
    </React.Fragment>
  );
};

const mapStateToProps = state => {
  return {
    secondNavbarLinks: get(state, ["qt", "data", "navigationMenu", "secondNavbarLinks"], []) || {},
    currentPath: get(state, ["qt", "currentPath"], "") || ""
  };
};
const SecondaryNavbar = connect(mapStateToProps)(SecondaryNavbarBase);

SecondaryNavbarBase.propTypes = {
  secondNavbarLinks: array,
  currentPath: string
};

export default SecondaryNavbar;
