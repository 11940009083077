/* eslint-disable max-len */
/* eslint-disable react/prop-types */
import React from "react";

const Whatsapp = ({ width = "24", height = "24" }) => (
  <svg
    id="Layer_1"
    data-name="Layer 1"
    xmlns="http://www.w3.org/2000/svg"
    version="1.1"
    viewBox="0 0 50 50"
    width={width}
    height={height}
  >
    <defs>
      <style>
        {`
          .cls-wp-1 {
            fill: #fff;
            stroke-width: 0px;
          }

          .cls-wp-2 {
            fill: none;
            stroke: #f4f4fa;
            stroke-miterlimit: 10;
            stroke-width: 0.75px;
          }
        `}
      </style>
    </defs>
    <path
      className="cls-wp-2"
      d="M47.5,25c0,12.43-10.07,22.5-22.5,22.5h0c-12.43,0-22.5-10.07-22.5-22.5h0C2.5,12.57,12.57,2.5,25,2.5h0c12.43,0,22.5,10.07,22.5,22.5h0Z"
    />
    <path
      className="cls-wp-1"
      d="M24.92,10.58c-6.95.23-12.7,5.86-13.05,12.81-.13,2.5.43,4.87,1.5,6.93l-1.51,6.76c-.06.31.21.58.51.51l6.76-1.51h0c2.04,1.07,4.39,1.62,6.87,1.5,6.94-.32,12.59-6.02,12.86-12.96.3-7.86-6.1-14.3-13.95-14.04ZM25.37,34.81c-1.97,0-3.81-.53-5.39-1.46-.15-.08-.29-.17-.43-.27l-4.32,1.15,1.15-4.32c-1.09-1.68-1.72-3.68-1.72-5.82,0-5.91,4.81-10.72,10.72-10.72s10.72,4.81,10.72,10.72-4.81,10.72-10.72,10.72Z"
    />
    <path
      className="cls-wp-1"
      d="M31.72,28.81c-.19.28-.38.54-.7.85-.69.69-1.64,1.03-2.61.93-1.73-.18-4.2-1.14-6.31-3.25-2.12-2.12-3.07-4.58-3.25-6.31-.1-.97.25-1.92.93-2.61.31-.31.57-.51.85-.7.52-.35,1.22-.12,1.42.47l.71,2.13c.2.58.11.95-.14,1.2l-.57.57c-.28.28-.33.71-.11,1.05.31.49.9,1.28,1.99,2.36,1.08,1.08,1.87,1.67,2.36,1.99.34.21.77.17,1.05-.11l.57-.57c.25-.25.62-.34,1.2-.14l2.13.71c.59.2.82.9.47,1.42Z"
    />
  </svg>
);

export default Whatsapp;
