import { renderIsomorphicComponent, renderComponent } from "@quintype/framework/client/start";
import { pickComponent } from "../isomorphic/pick-component";
import Header from "../isomorphic/components/rows/header";
import Footer from "../isomorphic/components/rows/footer";
import LoadingIndicatorComponent from "../isomorphic/components/atoms/loading-indicator";
import {
  SiteCaptureAd,
  MasterHeadAd,
  HomeLhsAd,
  HomeRhsAd,
  HomeBottomStripAd,
  HomeTickerAd
} from "../isomorphic/components/rows/ads";

export function preRenderApplication(store) {
  const hydrate = { hydrate: !global.qtLoadedFromShell };
  renderComponent(Header, "header", store, hydrate);
  renderComponent(LoadingIndicatorComponent, "loading-indicator", store);
  renderComponent(Footer, "footer", store, hydrate);
  renderComponent(SiteCaptureAd, "siteCaptureAd", store, hydrate);
  renderComponent(MasterHeadAd, "masterHeadAd", store, hydrate);
}

// This is a separate file as everything from here on is hot reloaded when the app changes
export function renderApplication(store) {
  renderComponent(HomeLhsAd, "homeLhsAd", store);
  renderComponent(HomeRhsAd, "homeRhsAd", store);
  renderComponent(HomeBottomStripAd, "homeBottomStripAd", store);
  renderComponent(HomeTickerAd, "homeTickerAd", store);
  renderIsomorphicComponent("container", store, pickComponent, {
    hydrate: !global.qtLoadedFromShell
  });
}
