/* eslint-disable react/prop-types */
import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import get from "lodash/get";

import "./header.m.css";
import MobileHeader from "../../organisms/mobile-header";
import DesktopHeader from "../../organisms/desktop-header";
import AdWrapper, { HptoAd } from "../ads";
import { GoToTop } from "../../story-templates/go-to-top";
import SecondaryNavbar from "../../molecules/navbar/secondaryNavbar";
import SubscriptionPopup from "../../molecules/subscription-popup";
import { getStoryBySlug } from "../../../utils/api";
import { isIplStory } from "../../../utils";

const Header = ({ pageType }) => {
  const [isIPL, setIsIPL] = useState(false);
  const [iplStory, setIplStory] = useState(false);

  useEffect(
    () => {
      const checkCustomAd = async () => {
        const currentUrl = window.location.pathname;
        if (pageType === "story-page") {
          const slug = currentUrl.slice(1);
          const storyData = await getStoryBySlug(slug);
          setIplStory(isIplStory(storyData.story));
        }
        if (currentUrl === "/topic/ipl-2024" || currentUrl === "/topic/wpl-2024") {
          setIsIPL(true);
        }
      };

      checkCustomAd();
    },
    [pageType]
  );

  const RenderAds = () => {
    if (pageType === "story-page") {
      if (iplStory) {
        return <AdWrapper name="IPLcontentheader" id="home-content-header" />;
      }
    } else {
      if (isIPL) {
        return <AdWrapper name="IPLcontentheader" id="home-IPLcontentheader" />;
      }
    }

    return <AdWrapper name="contentheader" id="home-content-header" />;
  };

  return (
    <React.Fragment>
      <MobileHeader />
      <DesktopHeader />
      <SecondaryNavbar />
      <div styleName="ad">
        <RenderAds />
      </div>
      <HptoAd />
      <GoToTop />
      <SubscriptionPopup />
    </React.Fragment>
  );
};

const mapStateToProps = state => {
  return {
    pageType: get(state, ["qt", "pageType"], "")
  };
};

export default connect(
  mapStateToProps,
  null
)(Header);
