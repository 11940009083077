import React from "react";
import { string, number } from "prop-types";

const RightArrow = ({ color = "#FFF", width = 20, height = 20 }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 11 11">
      <path
        fill={color}
        fillRule="nonzero"
        d="M5.61.39a.343.343 0 0 1 .21-.07c.078 0 .14.024.188.07l4.898 4.923c.063.046.094.109.094.187s-.031.14-.094.188l-4.898 4.921a.253.253 0 0 1-.188.07.343.343 0 0 1-.21-.07l-.47-.468a.253.253 0 0 1-.07-.188c0-.078.024-.148.07-.21l3.633-3.634H.781a.271.271 0 0 1-.199-.082.271.271 0 0 1-.082-.199v-.656c0-.078.027-.145.082-.2a.271.271 0 0 1 .2-.081h7.991L5.141 1.258a.343.343 0 0 1-.07-.211c0-.078.023-.14.07-.188l.468-.468z"
      />
    </svg>
  );
};

RightArrow.propTypes = {
  color: string,
  width: number,
  height: number
};

export default RightArrow;
