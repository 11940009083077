import wretch from "wretch";

const apiClient = wretch().options({ credentials: "same-origin" });

export const getRequest = (url, params) => {
  let client = apiClient.url(url);
  if (params) client = client.query(params);
  return client.get();
};

export const sendTransactionalEmail = body => {
  return global
    .wretch()
    .url("/transactional-emails")
    .headers({ "Content-Type": "application/json" })
    .post(body)
    .res(res => res);
};

export const expandedBannerDetails = () => {
  return global
    .wretch()
    .url("/banner-details")
    .get()
    .json(response => {
      return response;
    });
};

export const getStoryBySlug = slug => {
  return global
    .wretch("/api/v1/stories-by-slug")
    .query({
      slug: slug
    })
    .get()
    .json(response => {
      return response;
    });
};
