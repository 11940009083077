/* eslint-disable no-unused-vars */
/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable max-len */
/* eslint-disable prettier/prettier */
import React, { useState, useEffect, useRef } from "react";
import { string, func, bool, array } from "prop-types";
import { connect } from "react-redux";
import get from "lodash/get";
import SimpleModal from "../../molecules/popup-model";
import Close from "../../atoms/icons/close";
// import DownArrow from "../../atoms/icons/down-arrow";
import { expandedBannerDetails, getStoryBySlug } from "../../../utils/api";
import { isIplStory, shouldRemoveBanner } from "../../../utils";
// import HeaderLogo from "../../atoms/icons/header-logo";
// import { Link } from "@quintype/components";
import "./styles.m.css";


const showHpto = payload => {
  return {
    type: "SHOW_HPTO_AD",
    payload
  };
};

const handleSiteCaptureAd = payload => {
  return {
    type: "SHOW_SITE_CAPTURE_AD",
    payload
  };
};

function createCookie(name, value, min) {
  let expires = "";
  if (min) {
    const date = new Date();
    date.setTime(date.getTime() + min * 60 * 1000);
    expires = "; expires=" + date.toGMTString();
  } else expires = "";
  document.cookie = name + "=" + value + expires + "; path=/";
}

function readCookie(name) {
  const nameEQ = name + "=";
  const ca = document.cookie.split(";");
  for (let i = 0; i < ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) === " ") c = c.substring(1, c.length);
    if (c.indexOf(nameEQ) === 0) return c.substring(nameEQ.length, c.length);
  }
  return null;
}

const AdWrapperBase = props => {
  const adZoneRef = useRef(null);
  const { id, name, adPrefix, isCarouselAd } = props;

  useEffect(() => {
    setTimeout(()=>{
      const cards = document.createElement("script");
      cards.type = "text/javascript";
      const adSlotName = isCarouselAd ? name : `${adPrefix}-${name}`;
      cards.innerHTML = `OA_show("${adSlotName}", "${id}")`;
      if(name=== "lhs"){
        console.log("adSlotName", adSlotName);
      }
      adZoneRef.current.appendChild(cards);
    },3000)
  },[id,adPrefix]);

  return <div id={props.id} ref={adZoneRef} />;
};

AdWrapperBase.propTypes = {
  name: string,
  id: string,
  adPrefix: string,
  isCarouselAd: bool,
};

export const MasterHeadAd = () => {
  const [isIPL, setIsIPL] = useState(false);
  const [iplStory, setIplStory] = useState(false);

  useEffect(() => {
    const checkCustomAd = async () => {
      const currentUrl = window.location.pathname;
      if (currentUrl === "/topic/ipl-2024" || currentUrl === "/topic/wpl-2024") {
        setIsIPL(true);
      }
      const slug = currentUrl.slice(1);
      const storyData = await getStoryBySlug(slug);
      setIplStory(isIplStory(storyData.story));
    };

    checkCustomAd();
  }, []);
  if(isIPL || iplStory)
  {
    return (<AdWrapper name="IPLmastHead" id="home-IPLmastHead" />)
  }
  return (<AdWrapper name="mastHead" id="home-mastHead" />);
}
export const HomeLhsAd = () => {
  const [isIPL, setIsIPL] = useState(false);
  const [iplStory, setIplStory] = useState(false);
  const [removeBanner,setRemoveBanner] = useState(false);

  useEffect(() => {

    const checkCustomAd = async () => {
      const currentUrl = window.location.pathname;
      if (currentUrl === "/topic/ipl-2024" || currentUrl === "/topic/wpl-2024") {
        setIsIPL(true);
      }
      const slug = currentUrl.slice(1);
      const storyData = await getStoryBySlug(slug);
      setIplStory(isIplStory(storyData.story));
      if(shouldRemoveBanner(slug))
      {
        setRemoveBanner(true)
      }
      else{
        setRemoveBanner(false)
      }
    };

    checkCustomAd();

  }, []);

  if(removeBanner)
  {
    return null;
  }

  if(isIPL || iplStory)
  {
    return <AdWrapper id="home-IPLlhs" name="IPLlhs" />
  }
  return <AdWrapper id="lhs" name="lhs" />
}
export const HomeRhsAd = () => {
  const [isIPL, setIsIPL] = useState(false);
  const [iplStory, setIplStory] = useState(false);
  const [removeBanner,setRemoveBanner] = useState(false);

  useEffect(() => {

    const checkCustomAd = async () => {
      const currentUrl = window.location.pathname;
      if (currentUrl === "/topic/ipl-2024" || currentUrl === "/topic/wpl-2024") {
        setIsIPL(true);
      }
      const slug = currentUrl.slice(1);
      const storyData = await getStoryBySlug(slug);
      setIplStory(isIplStory(storyData.story));
      if(shouldRemoveBanner(slug))
      {
        setRemoveBanner(true)
      }
      else{
        setRemoveBanner(false)
      }

    };

    checkCustomAd();

  }, []);

  if(removeBanner)
  {
    return null;
  }

  if(isIPL || iplStory)
  {
    return (<AdWrapper id="home-IPLrhs" name="IPLrhs" />)
  }
  return (<AdWrapper id="rhs" name="rhs" />);
};
export const HomeBottomStripAd = () => <AdWrapper id="home-bottomStrip" name="bottomStrip" />;

const HomeTickerAdBase = ({pageType}) => {
  const [bannerImage,setbannerImage] = useState("");
  const [bannerLink,setBannerLink] = useState("");
  const [upArrow,setUpArrow] = useState("");
  const [downArrow,setDownArrow] = useState("");
  const [showBanner, setShowBanner] = useState(false);
  const [bannerClass,setBannerClass] = useState("extended-banner-wrapper");
  const [bottomWrapperClass,setBottomWrapperClass] = useState("bottom-ad-wrapper");
  const [bannerUp,setBannerUp] = useState(false)
  const [showIcon,setShowIcon] = useState(true);

  const [isIPL, setIsIPL] = useState(false);
  const [removeBanner,setRemoveBanner] = useState(false);

  useEffect(() => {
    const currentUrl = window.location.pathname;

    const checkCustomTag = async () => {

      const slug = currentUrl.slice(1);
      const storyData = await getStoryBySlug(slug);
      // return isIplStory(storyData.story);
      if (currentUrl === "/topic/ipl-2024" || currentUrl === "/topic/wpl-2024" || isIplStory(storyData.story)) {
        setIsIPL(true);
      }
      else {
        setIsIPL(false);
      }

    };
    checkCustomTag()

    // if (currentUrl === "/topic/ipl-2024" || currentUrl === "/topic/wpl-2024" || checkCustomTag()) {
    //   console.log("hello");
    //   setIsIPL(true);
    // }
    // else {
    //   setIsIPL(false);
    // }

    if(shouldRemoveBanner(currentUrl.slice(1)))
    {
      setRemoveBanner(true)
    }
    else{
      setRemoveBanner(false)
    }

  }, [pageType]);


  const handleIconWrapperUpClick = () => {
    setShowBanner(true);
    setBannerClass("extended-banner-wrapper go-up");
    setBannerUp(true);
    setShowIcon(false);
    setTimeout(()=>{
      setShowIcon(true);
    },2000)

  };

  const handleIconWrapperDownClick = () => {
    setBannerClass("extended-banner-wrapper go-down");
    setTimeout(()=>{
      document.getElementById("extended-banner").style.zIndex = "2";
    },1890);
    setShowIcon(false);
    setTimeout(()=>{
      setShowBanner(false)
      setBannerUp(false);
      setShowIcon(true);
    },2000)

  }

  const AutoExpandAndClose = ()=>{
    handleIconWrapperUpClick();
    setTimeout(()=>{
    handleIconWrapperDownClick();
    },8000)
  }

  useEffect(() => {
    expandedBannerDetails().then((response)=>{
      const res = JSON.parse(response.text)
      const content = JSON.parse(get(res,["static-page", "content"],""));
      console.log("static page data", content);
      setbannerImage(content["banner-image"]);
      setBannerLink(content["banner-link"])
      setUpArrow(content["up-arrow"]);
      setDownArrow(content["down-arrow"]);

    })
    const isActiveAd= document.getElementById("home-ticker").children.length >= 2;
    console.log("is active ad", isActiveAd);
    const currentTimeStamp = Date.now();
    const sessionTimeStamp = window.sessionStorage.getItem("sliderTime");
    const timeDifference = currentTimeStamp - parseInt(sessionTimeStamp);
    console.log("currentTimeStamp",currentTimeStamp,"sessionTimeStamp",sessionTimeStamp,"TimeDifference", timeDifference)
    let timeout;
    let interval;
    if(isActiveAd){
      timeout = setTimeout(()=>{
        if(sessionTimeStamp === null || timeDifference > 300000)
        {
          AutoExpandAndClose();
          window.sessionStorage.setItem("sliderTime", currentTimeStamp);
        }

      },3000)

      setInterval(()=>{
        if(sessionTimeStamp === null || timeDifference > 300000)
        {
          AutoExpandAndClose();
        }
      },6 * 10000 * 5)
    }
    else {
      setShowIcon(false);
    }

    return ()=>{
      clearTimeout(timeout);
      clearInterval(interval);
    }


  }, []);

  if(removeBanner)
  {
    return null;
  }

  if(isIPL)
  {
    return (<div styleName="home-ticker">
    <AdWrapper id="home-IPLticker" name="IPLticker" />
  </div>);
  }

  return (
    <React.Fragment>
    <div styleName={bottomWrapperClass}>
      {showIcon ? bannerUp ?
        <img styleName="icon-wrapper" id="down-arrow" onClick={handleIconWrapperDownClick} src={downArrow}/>
        :
        <img styleName="icon-wrapper up-arrow" id="up-arrow" onClick={handleIconWrapperUpClick} src={upArrow}/>
        : null
      }


      {showBanner && <div styleName={bannerClass} id="extended-banner">
        <a href={bannerLink} target="_blank">
          <img src={bannerImage}></img>
        </a>
      </div>}
      <div styleName="home-ticker">
        <AdWrapper id="home-ticker" name="ticker" />
      </div>
    </div>
  </React.Fragment>
  )

};

HomeTickerAdBase.propTypes = {
  pageType: string
}
const SiteCaptureAdBase = props => {
  const { disableAds, siteCapture } = props;
  const disableSiteCap = disableAds.find(i => i.title === "sitecap");
  const udateFlag = !!disableSiteCap;
  const [isIPL, setIsIPL] = useState(false);
  const [iplStory, setIplStory] = useState(false);
  const [specialStroy, setSpecialStroy] = useState(false);

  useEffect(() => {

    const checkCustomAd = async () => {
      const currentUrl = window.location.pathname;
      if (currentUrl === "/topic/ipl-2024") {
        setIsIPL(true);
      }
      const slug = currentUrl.slice(1);
      const storyData = await getStoryBySlug(slug);
      setIplStory(isIplStory(storyData.story));
      const specialSlugs = ["news/marketing-initiatives/have-brands-kept-up-with-changing-consumer-preferences-for-content-consumption-paytv-vs-streaming","news/marketing-initiatives/tata-ipl-on-jiocinema-beyond-cricket-a-gateway-to-brand-success"];
      if(specialSlugs.includes(storyData.story.slug))
      {
        setSpecialStroy(true);
      }
    };

    checkCustomAd();

  }, []);

  useEffect(
    () => {
      setTimeout(() => {
        if (!disableSiteCap || readCookie("noafsitecap") !== null) {
          return undefined;
        }
        props.handleSiteCaptureAd(true);
      }, 20000);

      setTimeout(() => {
        if (props.siteCapture) {
          hideSiteCapture();
        }
      }, 15000);
    },
    [udateFlag, siteCapture]
  );

  const hideSiteCapture = () => {
    const ad = document.getElementById("siteCaptureAd");
    const container = document.getElementById("containerWrapper");

    if (ad && ad.style.display !== "none") {
      ad.style.display = "none";
    }
    if (container) {
      container.style.visibility = "visible";
      container.style.opacity = 1;
    }
    createCookie("noafsitecap", "sitecap", 10);
    props.handleSiteCaptureAd(false);
    props.showHpto(true);

  };

  if (!disableSiteCap || !siteCapture) {
    return null;
  }

  return (
    <div styleName="container">
      {!(isIPL || iplStory || specialStroy) && <div styleName="wrapper">
        <div styleName="ad-wrapper">
          <div styleName="container model">
            <SimpleModal closeAd={hideSiteCapture} createCookie={() => createCookie("sitecapad", "sitecap", 10)}>
              <AdWrapper name="siteCapture" id="home-site-capture" />
            </SimpleModal>
          </div>
        </div>
      </div>}
    </div>
  );
};

class HptoAdBase extends React.Component {
  hideAd() {
    const adWrapper = document.getElementById("hptoAd");
    if (!adWrapper) return;
    adWrapper.style.display = "none";
    this.props.showHpto(false);
  }

  shouldComponentUpdate(nextProps) {
    if (this.props.showHptoAd !== nextProps.showHptoAd) {
      return true;
    }
    return false;
  }

  render() {
    const { showHptoAd, disableAds, pageType } = this.props;
    const disableHpto = disableAds.find(i => i.title && i.title.toLowerCase() === "hpto");

    if (!disableHpto || !showHptoAd || pageType !== "home-page") return null;
    if (readCookie("hptoad") !== null) return null;

    return (
      <div id="hptoAd" styleName="container model">
        <SimpleModal closeAd={() => this.hideAd()} createCookie={() => createCookie("hptoad", "hpto", 10)}>
          <AdWrapper name="hpto" id="home-hpto" />
        </SimpleModal>
      </div>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  showHpto: value => dispatch(showHpto(value)),
  handleSiteCaptureAd: value => dispatch(handleSiteCaptureAd(value))
});

const mapStateToProps = state => {
  return {
    showHptoAd: get(state, ["adsReducer", "showHptoAd"], false),
    siteCapture: get(state, ["adsReducer", "siteCapture"], false),
    adPrefix: get(state, ["adsReducer", "adPrefix"], "home"),
    disableAds: get(state, ["qt", "data", "navigationMenu", "ads"], []),
    pageType: get(state, ["qt", "pageType"], "home")
  };
};

const HomeTickerAd = connect(mapStateToProps,null)(HomeTickerAdBase);

const SiteCaptureAd = connect(
  mapStateToProps,
  mapDispatchToProps
)(SiteCaptureAdBase);

const HptoAd = connect(
  mapStateToProps,
  mapDispatchToProps
)(HptoAdBase);

const adPropTypes = {
  showHpto: func,
  handleSiteCaptureAd: func,
  showHptoAd: bool,
  siteCapture: bool,
  disableAds: array,
  pageType: string
};

HptoAdBase.propTypes = {
  ...adPropTypes
};

SiteCaptureAdBase.propTypes = {
  ...adPropTypes
};

const AdWrapper = connect(
  mapStateToProps,
  mapDispatchToProps
)(AdWrapperBase);

export default AdWrapper;
export { SiteCaptureAd, HptoAd, showHpto,HomeTickerAd };
