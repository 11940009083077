/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from "react";
import throttle from "lodash/throttle";
import { GoTopIcon } from "../../atoms/icons/gotopicon";
import "./go-to-top.m.css";

export const GoToTop = () => {
  const [isScrolling, setIsScrolling] = useState(false);

  const onClick = e => {
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
  };

  useEffect(() => {
    let scrollingTimeout;

    const handleScroll = e => {
      setIsScrolling(true);
      if (document.body.scrollTop > 500 || document.documentElement.scrollTop > 500) {
        document.getElementById("go-to-top-wrapper").style.display = "block";
      } else {
        document.getElementById("go-to-top-wrapper").style.display = "none";
      }
      scrollingTimeout = setTimeout(() => {
        setIsScrolling(false);
      }, 1000);
    };

    window.addEventListener("scroll", throttle(handleScroll, 100));
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  });

  return (
    <div id="go-to-top-wrapper" styleName="wrapper" onClick={onClick} title="go to top">
      {!isScrolling && <GoTopIcon height={50} width={50} />}
    </div>
  );
};
