import React, { useState } from "react";
import PropTypes from "prop-types";
import wretch from "wretch";

import "./newsletter-subscription.m.css";
import FooterLogo from "../icons/footer-logo/index";
import RightArrow from "../icons/right-arrow/index";

export const NewsletterSubscription = () => {
  const [email, setEmail] = useState("");
  const [subscribeStatus, setSubscribeStatus] = useState(false);

  const handleEmailUpdate = e => setEmail(e.target.value);

  const handleSubmitEmail = async e => {
    e.preventDefault();
    await wretch("/newsletter-subscription")
      .query({ email })
      .get()
      .json(res => {
        setSubscribeStatus(res.status === "success");
      });
  };

  return (
    <div styleName="wrapper">
      <div>
        <form className="validate" noValidate styleName="validate" onSubmit={handleSubmitEmail}>
          <div>
            <label htmlFor="subscriber-email" styleName="newsletter-text">
              <p styleName="subscription-text">Stay updated with</p>
              <div styleName="subscription-text-wrapper">
                <span styleName="footer-logo-container">
                  <FooterLogo />
                </span>
                <p styleName="subscription-text">newsletter</p>
              </div>
            </label>
            {subscribeStatus ? (
              <span styleName="message">A confirmation email is sent</span>
            ) : (
              <div styleName="email-container">
                <input
                  type="email"
                  name="email"
                  onChange={handleEmailUpdate}
                  value={email}
                  styleName="email"
                  id="subscriber-email"
                  placeholder="Email Address"
                  required
                />
                <button type="submit" styleName="button" className="button">
                  <RightArrow />
                </button>
              </div>
            )}
          </div>
        </form>
      </div>
    </div>
  );
};

NewsletterSubscription.propTypes = {
  zohoRefreshToken: PropTypes.string,
  awebezohoClientIdrList: PropTypes.string,
  zohoClientSecret: PropTypes.string,
  listKey: PropTypes.string
};
