import React from "react";

const FooterLogo = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="44" height="21">
      <g fill="none">
        <path
          fill="#FFFFFE"
          d="M16.3 2.68l.68-2.52A6.1 6.1 0 0 0 15.2 0c-1.33 0-2.84.92-3.34 2.8l-.49 1.81h-.84l-.7 2.62h.84l-2.52 9.42h2.87l2.52-9.42h1.21l.7-2.62h-1.21l.34-1.26c.14-.53.44-.8.9-.8.32 0 .58.04.81.13m.9 11.72c-.18.14-.35.19-.63.19-.48 0-.5-.42-.16-1.7.37-1.4.65-1.77 1.07-1.77.3 0 .42.05.55.16l-.83 3.12zm2.44 2.25l2.43-9.08c.64-2.36-.26-3.21-2.42-3.21-1.25 0-2.77.62-3.88 1.35l.32 2.2c1.02-.62 1.74-.94 2.36-.94.57 0 .66.28.48.94l-.35 1.31a1.97 1.97 0 0 0-1.2-.32c-1.9 0-3.1 1.33-3.83 4.04-.73 2.73-.35 3.96 1.28 3.96.73 0 1.29-.27 1.92-.82l-.16.57h3.05zm5.87-2.59c-.22.16-.44.28-.64.28-.46 0-.58-.33.32-3.7.92-3.41 1.2-3.69 1.63-3.69.25 0 .42.14.52.28l-1.83 6.83zm1.33 5.92L30.95 4.6h-2.9l-.15.53a1.73 1.73 0 0 0-1.44-.78c-1.67 0-3.04 1.95-4.22 6.35-1.07 4-1 6.2.84 6.2.73 0 1.4-.37 1.9-.83l-1.29 4.88 3.15-.98zm9.38-6.45c.45-1.67.35-2.86-1.26-4.2-1.02-.8-1.3-1.16-1.06-2.04.11-.4.36-.64.75-.64.36 0 .45.44.48 1.38l2.64-.87c-.07-1.82-.58-2.8-2.46-2.8-2.11 0-3.61 1.33-4.15 3.32-.42 1.56-.22 2.71 1.37 3.97.98.78 1.14 1.22.88 2.18-.14.53-.41.78-.85.78-.37 0-.5-.43-.47-1.67l-2.68.87c-.05 1.88.43 3.1 2.42 3.1a4.35 4.35 0 0 0 4.39-3.38"
        />
        <path
          fill="#F3B229"
          d="M4 14.4c-.18.14-.35.19-.63.19-.48 0-.5-.42-.16-1.7.37-1.4.66-1.77 1.07-1.77.3 0 .42.05.55.16L4 14.4zm2.44 2.25l2.43-9.08c.64-2.36-.26-3.21-2.41-3.21-1.26 0-2.78.62-3.9 1.35l.33 2.2c1.02-.62 1.74-.94 2.36-.94.58 0 .66.28.48.94l-.35 1.31a1.97 1.97 0 0 0-1.2-.32c-1.9 0-3.1 1.33-3.82 4.04-.73 2.73-.36 3.96 1.27 3.96.74 0 1.29-.27 1.92-.82l-.15.57h3.04zM41.78 8.1l2-7.5H40.8l-2 7.5-.69 4.26h2.07l1.6-4.26m-1.67 7.08c.27-1.03-.22-1.74-1.26-1.74a2.3 2.3 0 0 0-2.2 1.74c-.27 1.01.25 1.72 1.28 1.72s1.9-.7 2.18-1.72"
        />
      </g>
    </svg>
  );
};

export default FooterLogo;
