/* eslint-disable max-len */
/* eslint-disable react/prop-types */
import React from "react";

const Youtube = ({ width = "24", height = "24" }) => (
  <svg
    id="Layer_1"
    data-name="Layer 1"
    xmlns="http://www.w3.org/2000/svg"
    version="1.1"
    viewBox="0 0 50 50"
    width={width}
    height={height}
  >
    <defs>
      <style>
        {`
          .cls-yt-1 {
            fill: none;
            stroke: #f7f7f9;
            stroke-miterlimit: 10;
            stroke-width: .75px;
          }
          .cls-yt-2 {
            fill: #fff;
            stroke-width: 0px;
          }
        `}
      </style>
    </defs>
    <path
      className="cls-yt-1"
      d="M47.5,25c0,12.43-10.07,22.5-22.5,22.5h0c-12.43,0-22.5-10.07-22.5-22.5h0C2.5,12.57,12.57,2.5,25,2.5h0c12.43,0,22.5,10.07,22.5,22.5h0Z"
    />
    <path
      className="cls-yt-2"
      d="M34.7,15.47H15.3c-1.92,0-3.47,1.55-3.47,3.47v12.13c0,1.92,1.55,3.47,3.47,3.47h19.39c1.92,0,3.47-1.55,3.47-3.47v-12.13c0-1.92-1.55-3.47-3.47-3.47ZM28.12,25.84l-5.39,2.69c-.62.31-1.35-.14-1.35-.84v-5.39c0-.7.73-1.15,1.35-.84l5.39,2.7c.69.34.69,1.33,0,1.67Z"
    />
  </svg>
);

export default Youtube;
