/* eslint-disable max-len */
/* eslint-disable react/prop-types */
import React from "react";

const Linkedin = ({ width = "24", height = "24" }) => (
  <svg
    id="Layer_1"
    data-name="Layer 1"
    xmlns="http://www.w3.org/2000/svg"
    version="1.1"
    viewBox="0 0 50 50"
    width={width}
    height={height}
  >
    <defs>
      <style>
        {`
          .cls-ld-1 {
            fill: none;
            stroke: #f8f7f7;
            stroke-miterlimit: 10;
            stroke-width: 0.75px;
          }

          .cls-ld-2 {
            fill: #fff;
            stroke-width: 0px;
          }
        `}
      </style>
    </defs>
    <path
      className="cls-ld-1"
      d="M47.5,25c0,12.43-10.07,22.5-22.5,22.5h0c-12.43,0-22.5-10.07-22.5-22.5h0C2.5,12.57,12.57,2.5,25,2.5h0c12.43,0,22.5,10.07,22.5,22.5h0Z"
    />
    <g>
      <circle className="cls-ld-2" cx="17.03" cy="16.09" r="2.9" />
      <rect className="cls-ld-2" x="14.62" y="20.92" width="4.83" height="14.49" rx="0.22" ry="0.22" />
      <path
        className="cls-ld-2"
        d="M35.87,26.23v8.21c0,.53-.43.97-.97.97h-2.9c-.53,0-.97-.43-.97-.97v-6.76c0-1.33-1.08-2.41-2.41-2.41s-2.41,1.08-2.41,2.41v6.76c0,.53-.43.97-.97.97h-2.9c-.53,0-.97-.43-.97-.97v-12.56c0-.53.43-.97.97-.97h2.9c.53,0,.97.43.97.97v.62c.97-1.25,2.56-2.07,4.35-2.07,2.67,0,5.31,1.93,5.31,5.8Z"
      />
    </g>
  </svg>
);

export default Linkedin;
