import React from "react";
import { connect } from "react-redux";
import get from "lodash/get";
import FooterMenuColumn from "../../atoms/footer-menu-column/index";

import "./footer-menu-group.m.css";
import PropTypes from "prop-types";

const FooterMenuGroupBase = ({ footerLinks }) => {
  const parentLinks = footerLinks.filter(item => item["parent-id"] === null);

  const formMenuColumns = () =>
    parentLinks.map((parentLink, index) => <FooterMenuColumn key={index} footerMenuColumn={parentLink} />);

  return <div styleName="wrapper">{formMenuColumns()}</div>;
};

const mapStateToProps = state => {
  return {
    footerLinks: get(state, ["qt", "data", "navigationMenu", "footerLinks"], []) || {}
  };
};

export const FooterMenuGroup = connect(mapStateToProps)(FooterMenuGroupBase);

FooterMenuGroupBase.propTypes = {
  footerLinks: PropTypes.array
};
