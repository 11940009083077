import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import get from "lodash/get";

import { string } from "prop-types";

import { Link } from "@quintype/components";
import HeaderLogo from "../../atoms/icons/header-logo";

import AdWrapper from "../../rows/ads";

import "./desktop-logo-bar.m.css";
import { getStoryBySlug } from "../../../utils/api";
import { isIplStory } from "../../../utils";

const DesktopLogoBar = ({ pageType }) => {
  const [isIPL, setIsIPL] = useState(false);
  const [iplStory, setIplStory] = useState(false);

  useEffect(
    () => {
      const checkCustomAd = async () => {
        const currentUrl = window.location.pathname;
        if (pageType === "story-page") {
          const slug = currentUrl.slice(1);
          const storyData = await getStoryBySlug(slug);
          setIplStory(isIplStory(storyData.story));
        }
        if (currentUrl === "/topic/ipl-2024" || currentUrl === "/topic/wpl-2024") {
          setIsIPL(true);
        }
      };

      checkCustomAd();
    },
    [pageType]
  );

  const RenderAds = () => {
    if (pageType === "story-page") {
      if (iplStory) {
        return <AdWrapper id="home-topBanner" name="IPLtopBanner" />;
      } else {
        return <AdWrapper id="home-topBanner" name="topBanner" />;
      }
    } else {
      if (isIPL) {
        return <AdWrapper id="home-IPLtopBanner" name="IPLtopBanner" />;
      }
    }
    return <AdWrapper id="home-topBanner" name="topBanner" />;
  };

  return (
    <div styleName="wrapper">
      <Link href="/">
        <HeaderLogo width={"200"} height={"94"} className={"desktop"} />
      </Link>
      <div styleName="ad">
        <RenderAds />
      </div>
    </div>
  );
};
DesktopLogoBar.propTypes = {
  pageType: string
};
const mapStateToProps = state => {
  return {
    pageType: get(state, ["qt", "pageType"], "")
  };
};

export default connect(
  mapStateToProps,
  null
)(DesktopLogoBar);
