import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import get from "lodash/get";

import "./footer.m.css";
import { NewsletterSubscription } from "../../atoms/newsletter-subscription";
import { FooterMenuGroup } from "../../molecules/footer-menu-group";
import { SocialLinksWithLabel } from "../../organisms/social-links-with-label";
import QuintypeIcon from "../../atoms/icons/quintype-logo";

const FooterBase = ({ copyrightText, pageType }) => {
  if (pageType === "home-page") return null;

  return (
    <React.Fragment>
      <div styleName="wrapper">
        <div styleName="top">
          <NewsletterSubscription />
          <div>
            <SocialLinksWithLabel text="Follow us on :" />
            <p styleName="copyright">{copyrightText}</p>
          </div>
        </div>
        <FooterMenuGroup />
        <div styleName="powered-by">
          <QuintypeIcon color="#fff" width={24} height={24} />
          <a
            href="https://www.quintype.com/"
            target="_blank"
            rel="noopener noreferrer"
            style={{ fontFamily: "var(--sansTypeface)" }}
          >
            Powered by Quintype
          </a>
        </div>
      </div>
    </React.Fragment>
  );
};

const mapStateToProps = state => {
  return {
    copyrightText: get(state, ["qt", "config", "publisher-settings", "copyright"], ""),
    pageType: get(state, ["qt", "pageType"], "")
  };
};

FooterBase.propTypes = {
  copyrightText: PropTypes.string,
  pageType: PropTypes.string
};

const Footer = connect(mapStateToProps)(FooterBase);

export default Footer;
