import React from "react";
import { Link } from "@quintype/components";
import "./footer-menu-column.m.css";
import { object } from "prop-types";

class FooterMenuColumn extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showDropdown: false
    };
  }

  generateChildItemsDom = childItems =>
    childItems.map((childItem, index) => (
      <li key={index}>
        <Link
          href={childItem.completeUrl}
          styleName="child-item"
          externalLink={childItem.isExternalLink && childItem.completeUrl}
        >
          {childItem.title}
        </Link>
      </li>
    ));

  render() {
    const { footerMenuColumn } = this.props;
    const childItems = footerMenuColumn.children || [];
    const childItemsDom = this.generateChildItemsDom(childItems);

    return (
      <ul>
        {footerMenuColumn.title && (
          <li>
            <Link
              styleName="parent-menu-item"
              href={footerMenuColumn.completeUrl || "#"}
              externalLink={footerMenuColumn.isExternalLink && footerMenuColumn.completeUrl}
            >
              {footerMenuColumn.title}
            </Link>
          </li>
        )}
        <ul>{childItemsDom}</ul>
      </ul>
    );
  }
}

export default FooterMenuColumn;

FooterMenuColumn.propTypes = {
  footerMenuColumn: object
};
